<template lang="">
  <BSidebar
    id="tour-list-sidebar"
    shadow
    right
    backdrop
    lazy
    title="Cài đặt bộ lọc và hiển thị"
  >
    <div
      style="background-color: #ccc; height: 100%;"
      class="p-50"
    >
      <slot />
    </div>
  </BSidebar>
</template>
<script>
import { BSidebar } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
}
</script>
<style lang="">

</style>
