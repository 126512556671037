var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BSidebar', {
    attrs: {
      "id": "tour-list-sidebar",
      "shadow": "",
      "right": "",
      "backdrop": "",
      "lazy": "",
      "title": "Cài đặt bộ lọc và hiển thị"
    }
  }, [_c('div', {
    staticClass: "p-50",
    staticStyle: {
      "background-color": "#ccc",
      "height": "100%"
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }